import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { transparentize, readableColor, darken } from 'polished'
import styled from 'styled-components'
import { config, useSpring, animated } from 'react-spring'
import Layout from '../components/layout'
import { Box, AnimatedBox, Button } from '../elements'
import SEO from '../components/SEO'

const PBox = styled(AnimatedBox)`
  max-width: 1400px;
  margin: 0 auto;
`

const Content = styled(Box)<{ bg: string }>`
  background-color: ${props => transparentize(0.0, props.bg)};

  .gatsby-image-wrapper {
      max-width: 900px;
      margin: 0 auto;
  }

  .gatsby-image-wrapper:not(:last-child) {
    margin-bottom: ${props => props.theme.space[10]};

    @media (max-width: ${props => props.theme.breakpoints[3]}) {
      margin-bottom: ${props => props.theme.space[8]};
    }
  }
`

const Category = styled(AnimatedBox)`
  letter-spacing: 0.05em;
  font-size: ${props => props.theme.fontSizes[1]};
  text-transform: uppercase;
`

const Description = styled(animated.div)`
  max-width: 960px;
  letter-spacing: -0.003em;
  --baseline-multiplier: 0.179;
  --x-height-multiplier: 0.35;
  line-height: 1.58;
`
const PButton = styled(Button)<{ color: string }>`
  background: ${props => (props.color === 'white' ? 'black' : props.color)};
  color: ${props => readableColor(props.color === 'white' ? 'black' : props.color)};
  :hover {
    background: black;
    color: white;
    transition: 0.6s ease 0s !important;
    cursor: pointer;
  
`
const IpadBox = styled.div`
	margin: 0;
	display: flex;
	align-items: flex-end;
	justify-content: center;
  overflow:visible;
  margin-bottom: 2em;
`

const Ipad2 = styled.div`
  display: block;
	background-color: #000;
	background-size: auto 200%, cover;
	background-repeat: no-repeat, no-repeat;
	border: solid #F2F2F4;
	position: relative;
	box-shadow: 0 0.2em 1em 0.1em rgba(0, 0, 0, 0.13), 0 0.3px 0 0 #FAFAFA inset;
	transition: all .1s linear, line-height 0s linear;
	background-position: 50% 100%, center;
	transform-origin: bottom center;
	height: 50vmin;
	width: calc(1.43*50vmin);
	line-height: calc(1.43*50vmin);
	border-width: calc(50vmin/20);
	border-radius: calc(50vmin/14);
	background-size: cover;
  z-index: 100;
`

type PageProps = {
  data: {
    project: {
      title_detail: string
      end_title: string
      color: string
      category: string
      desc: string
      slug: string
      gif_url: string
      gif: string
      desclink: string
      desclinktitle: string
      parent: {
        modifiedTime: string
        birthTime: string
      }
      cover: {
        childImageSharp: {
          resize: {
            src: string
          }
        }
      }
    }
    images: {
      nodes: {
        name: string
        childImageSharp: {
          fluid: {
            aspectRatio: number
            src: string
            srcSet: string
            sizes: string
            base64: string
            tracedSVG: string
            srcWebp: string
            srcSetWebp: string
          }
        }
      }[]
    }
  }
}

const Project: React.FunctionComponent<PageProps> = ({ data: { project, images } }) => {
  const categoryAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0, transform: 'translate3d(0, -30px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)'},
  })


  const titleAnimation = useSpring({ config: config.slow, delay: 300, from: { opacity: 0 }, to: { opacity: 1 } })
  const descAnimation = useSpring({ config: config.slow, delay: 600, from: { opacity: 0 }, to: { opacity: 1 } })
  const imagesAnimation = useSpring({ config: config.slow, delay: 800, from: { opacity: 0 }, to: { opacity: 1 } })
  const gifAnimation = useSpring({ config: config.slow, delay: 1000, from: { opacity: 0 }, to: { opacity: 1 } })
  const helpAnimation = useSpring({ config: config.slow, delay: 1300, from: { opacity: 0, transform: 'translate3d(0, 0, -30px)' }, to: { opacity: 1, transform: 'translate3d(0, 0, 0)' } })
  const gifOn = (project.gif)

  const ColorBox = styled(AnimatedBox)<{ color: string }>`
      max-width: 1400px;
      margin: 0 auto;
      color: ${props => readableColor(`${project.color}`, darken(0.30,`${project.color}`),`${project.color}`)};
  `
  const ProjectTitle = styled(animated.h4)<{ color: string }>`
        font-family: 'GT-Walsheim-Pro', serif;
        color: ${props => readableColor(`${project.color}`,'#211e26','#e7ebed')};
  ` 
  return (
    <Layout color={project.color}>
      <SEO
        pathname={project.slug}
        title={`${project.title_detail} | ASC Studio Dublin`}
        desc={project.desc}
        node={project.parent}
        banner={project.cover.childImageSharp.resize.src}
        individual
      />
      <PBox py={10} px={[6, 6, 8, 10]}>
        <Category style={categoryAnimation}>{project.category}</Category>
        <animated.h1 style={titleAnimation}>{project.title_detail}</animated.h1>
        <Description style={descAnimation}>
          <div dangerouslySetInnerHTML={{ __html: project.desc }} />
          <PBox py={2} px={[0, 0, 0, 0]}><Link to={project.desclink} aria-label="ASC Studio">
          {project.desclinktitle}</Link></PBox>
        </Description>
          <ColorBox style={{borderBottom: '1px solid'}}>
          <animated.h3 style={gifAnimation}>{project.end_title}</animated.h3>
          <animated.p style={helpAnimation}>We would be delighted to discuss your requirements, <Link to="/contact">please get in touch.</Link></animated.p>
          </ColorBox>
      </PBox>
      <Content bg={project.color} py={5}>
        <PBox style={imagesAnimation} px={[6, 6, 8, 10]}>
        <ProjectTitle style={titleAnimation}>Project /</ProjectTitle>
        <PBox px={[10, 10, 12, 12, 13, 13]}>
          {images.nodes.map(image => (
            <Img alt={image.name} key={image.childImageSharp.fluid.src} fluid={image.childImageSharp.fluid} />
          ))} 
          </PBox>
          {gifOn==='1' && 
          <PBox style={imagesAnimation} py={12} px={[6, 6, 8, 10]}>
           <IpadBox>
           <Ipad2 style={{backgroundImage: `url(${project.gif_url})`}}></Ipad2>
            </IpadBox>
          </PBox>}
        </PBox>
      </Content>
      <PBox style={{ textAlign: 'center' }} py={8} px={[6, 6, 8, 10]}>
        <h3>Starting your own project?</h3>
        <Link to="/contact">
        <PButton color={project.color} py={3} px={8}>
        Contact Us
        </PButton>
        </Link>
      </PBox>
    </Layout>  
  )
}

export default Project

export const query = graphql`
  query ProjectTemplate($slug: String!, $images: String!) {
    project: projectsYaml(slug: { eq: $slug }) {
      title_detail
      end_title
      color
      category
      desc
      slug
      gif_url
      gif
      desclink
      desclinktitle
      parent {
        ... on File {
          modifiedTime
          birthTime
        }
      }
      cover {
        childImageSharp {
          resize(width: 1200, height: 675, quality: 80) {
            src
          }
        }
      }
    }
    images: allFile(filter: { relativePath: { regex: $images } }, sort: { fields: name, order: ASC }) {
      nodes {
        name
        publicURL
        childImageSharp {
          fluid(quality: 95, maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }

`
